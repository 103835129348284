import { useEffect, useReducer, ReactNode } from 'react'
import { Icon } from '@sh24/ui-components'
import { useRouter } from 'next/router'
import documentToReactComponents from '../../../utils/document-to-react-components'
import bookingServices from '../../../services/consultation-booking'
import ConsultationBooking from './consultation-booking'
import InvitationError from './invitation-error'
import { slotsGroupedByDate } from './utils'
import reducer, { ActionTypes, initialState } from './reducer'
import { ErrorCode, ErrorData } from '../../../types/api'
import { Slot } from './types'

const content = (copy: unknown): ReactNode | null => (
  copy ? documentToReactComponents(copy) : null
)

const LoadingPage = () => (
  <div className="row row-centered">
    <Icon name="loading" animation="spin" />
  </div>
)

const scrollToTop = () => (
  document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
)

const ConsultationAppointmentBookingModule = ({
  bookingCopy,
  bookedCopyTop,
  bookedCopyBottom,
} : {
  bookingCopy: unknown,
  bookedCopyTop: unknown,
  bookedCopyBottom: unknown,
}) => {
  const { query, isReady } = useRouter()
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    bookedAt,
    dataFetched,
    invitationError,
    loading,
    selectedSlot,
    slotsByDate,
    slotTaken,
    token,
  } = state

  useEffect(() => scrollToTop(), [slotTaken, invitationError, token])

  const handleError = (errorData: ErrorData) => {
    const code = errorData.errors[0]?.code

    switch (code) {
      case ErrorCode.CONSULTATION_BOOKING_SLOT_TAKEN: {
        return dispatch({ type: ActionTypes.SLOT_TAKEN })
      }
      case ErrorCode.CONSULTATION_BOOKING_RESOURCE_CONFLICT: {
        return dispatch({ type: ActionTypes.RECOVERABLE_ERROR_OCCURRED })
      }
      default: {
        return dispatch({ type: ActionTypes.UNRECOVERABLE_ERROR_OCCURRED })
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const invitationResponse = await bookingServices.getInvitation(token)
      const availableSlotsResponse = await bookingServices.getAvailableSlots(token)

      if ('errors' in invitationResponse) return handleError(invitationResponse)
      if ('errors' in availableSlotsResponse) return handleError(availableSlotsResponse)

      return dispatch({
        type: ActionTypes.DATA_FETCHED,
        bookedAt: invitationResponse.data.appointment_scheduled_at,
        slotsByDate: slotsGroupedByDate(availableSlotsResponse.data.available_slots),
      })
    }

    if (token) {
      fetchData()
    } else if (isReady && query.token) {
      dispatch({
        type: ActionTypes.PAGE_READY,
        token: Array.isArray(query.token) ? query.token[0] : query.token,
      })
    }
  }, [isReady, token])

  const bookSlot = async (slot: Slot) => {
    dispatch({ type: ActionTypes.SLOT_BOOKING_REQUESTED, selectedSlot: slot })

    const parsedResponse = await bookingServices.bookSlot(token, slot.uid)
    if ('errors' in parsedResponse) return handleError(parsedResponse)

    return dispatch({ type: ActionTypes.SLOT_BOOKED })
  }

  const cancelAppointment = async () => {
    dispatch({ type: ActionTypes.APPOINTMENT_CANCELLATION_REQUESTED })

    const parsedResponse = await bookingServices.cancelAppointment(token)
    if ('errors' in parsedResponse) return handleError(parsedResponse)

    return dispatch({ type: ActionTypes.APPOINTMENT_CANCELLED })
  }

  const componentToRender = () => {
    if (invitationError) {
      return InvitationError
    } if (dataFetched) {
      return ConsultationBooking
    }
    return LoadingPage
  }

  const Component = componentToRender()

  return (
    <Component
      bookedAt={bookedAt}
      slotsByDate={slotsByDate}
      bookFunction={bookSlot}
      cancelFunction={cancelAppointment}
      selectedSlot={selectedSlot}
      loading={loading}
      slotTaken={slotTaken}
      topCopy={bookedAt ? content(bookedCopyTop) : content(bookingCopy)}
      bottomCopy={bookedAt ? content(bookedCopyBottom) : null}
    />
  )
}

export default ConsultationAppointmentBookingModule
