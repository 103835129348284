import React from 'react'
import styled from 'styled-components'
import { Button, ContraceptionComparisonSelectionCard } from '@sh24/ui-components'
import { ContraceptionOption } from './types'
import Image from '../../Image/image'

interface ContraceptionComparisonToolSelectionProps {
  resetButtonText: string,
  options: ContraceptionOption[],
  selectedOptions: ContraceptionOption[],
  addToSelectionHandler: (contraceptionId: string) => void,
  removeFromSelectionHandler: (contraceptionId: string) => void,
  resetSelectionHandler: () => void,
}

const ContraceptionComparisonOptionList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-rows: 166px;
  grid-gap: 5px;
`

const ResetButtonContainer = styled.div`
  margin-top: 20px;
  button {
    width: 100%;
  }
`

const selection = ({
  resetButtonText,
  options,
  selectedOptions,
  addToSelectionHandler,
  removeFromSelectionHandler,
  resetSelectionHandler,
} : ContraceptionComparisonToolSelectionProps) => {
  const optionCards = options.map((option) => {
    const selected = selectedOptions.includes(option)

    const image = (
      <Image
        image={option.image}
        maxHeight={60}
        maxWidth={60}
        layout="intrinsic"
      />
    )

    return (
      <ContraceptionComparisonSelectionCard
        key={option.id}
        id={option.id}
        name={option.name}
        image={image}
        selectHandler={addToSelectionHandler}
        deselectHandler={removeFromSelectionHandler}
        selected={selected}
      />
    )
  })

  return (
    <>
      <ContraceptionComparisonOptionList>
        {optionCards}
      </ContraceptionComparisonOptionList>
      <ResetButtonContainer>
        <Button
          text={resetButtonText}
          fullWidth
          onClick={resetSelectionHandler}
        />
      </ResetButtonContainer>
    </>
  )
}

export default selection
