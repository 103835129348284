const productToBasketItem = (product) => {
  const selectedOption = product.options?.find((opt) => opt.selected)

  return {
    productId: product.productId,
    productName: product.productName,
    name: product.name,
    identifier: product.identifier,
    byline: product.byline,
    price: product.price,
    selectedOption: selectedOption ? {
      productId: selectedOption.productId,
      productName: selectedOption.productName,
      name: selectedOption.name,
      identifier: selectedOption.identifier,
      price: selectedOption.price,
      isExtra: selectedOption.isExtra,
    } : null,
    mandatory: product.mandatory,
    preselected: product.preselected,
    isExtra: product.isExtra,
  }
}

export default productToBasketItem
