import React from 'react'
import styled from 'styled-components'
import { Button } from '@sh24/ui-components'
import { ContraceptionOption } from './types'
import Links from './detail/links'
import Header from './detail/header'
import CardWithHeadingBubble from './detail/card-with-heading-bubble'
import CardWithHeading from './detail/card-with-heading'
import CardWithTextOnly from './detail/card-with-text-only'

interface DetailProps {
  selectedOptions: ContraceptionOption[],
  compareOtherOptionsHandler: () => void,
  removeFromSelectionHandler: (contraceptionId: string) => void,
  compareOtherOptionsButtonText: string,
}

interface Content {
  id: string,
  mainContent: string | boolean,
  moreInfo?: string
}

const CompareOtherButtonContainer = styled.div`
  margin-top: 20px;
  button {
    width: 100%;
  }
`

const Container = styled.div`
    margin-top: 20px;
`

const detail = ({
  selectedOptions,
  compareOtherOptionsHandler,
  removeFromSelectionHandler,
  compareOtherOptionsButtonText,
}: DetailProps) => {
  const extractFieldsFromSelectionOptions = ({
    mainContentField,
    moreInfoField,
  }: {
    mainContentField: string,
    moreInfoField?: string
  }) => (
    selectedOptions.map((option) => ({
      id: option.id,
      mainContent: option[mainContentField as keyof ContraceptionOption],
      moreInfo: option[moreInfoField as keyof ContraceptionOption],
    } as Content))
  )

  return (
    <Container>
      <Header
        selectedOptions={selectedOptions}
        removeFromSelectionHandler={removeFromSelectionHandler}
      />
      <CardWithTextOnly
        content={extractFieldsFromSelectionOptions({ mainContentField: 'description' })}
      />
      <CardWithHeadingBubble
        heading="Effectiveness"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'effectiveness', moreInfoField: 'effectivenessMoreInfo' })}
        background
      />
      <CardWithHeadingBubble
        heading="Length of use"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'lengthOfUse', moreInfoField: 'lengthOfUseMoreInfo' })}
        background={false}
      />
      <CardWithHeading
        heading="Periods and bleeding"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'periodsAndBleeding' })}
        background
      />
      <CardWithHeading
        heading="Possible side effects"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'possibleSideEffects' })}
        background={false}
      />
      <CardWithHeading
        heading="Hormones"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'hormones' })}
        background
      />
      <CardWithHeading
        heading="Impact on fertility"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'impactOnFertility' })}
        background={false}
      />
      <CardWithHeading
        heading="Health risks"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'healthRisks' })}
        background
      />
      <CardWithHeading
        heading="Health benefits"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'healthBenefits' })}
        background={false}
      />
      <CardWithHeading
        heading="A good option if..."
        content={extractFieldsFromSelectionOptions({ mainContentField: 'aGoodOptionIf' })}
        background
      />
      <CardWithHeading
        heading="Weight"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'weight' })}
        background={false}
      />
      <CardWithHeading
        heading="Mental health"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'mentalHealth' })}
        background
      />
      <CardWithHeading
        heading="Discreetness"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'discreetness' })}
        background={false}
      />
      <CardWithHeading
        heading="What do I need to remember?"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'whatDoINeedToRemember' })}
        background
      />
      <CardWithHeading
        heading="STI Protection"
        content={extractFieldsFromSelectionOptions({ mainContentField: 'stiProtection' })}
        background={false}
      />
      <Links selectedOptions={selectedOptions} />
      <CompareOtherButtonContainer>
        <Button
          text={compareOtherOptionsButtonText}
          fullWidth
          variation="brand"
          onClick={compareOtherOptionsHandler}
        />
      </CompareOtherButtonContainer>
    </Container>
  )
}

export default detail
