import { PropsWithChildren, useContext, useMemo } from 'react'
import { ButtonLink, Content as UiContent } from '@sh24/ui-components'
import type { ConditionalTextBlock as ConditionalTextBlockType } from '@sh24/contentful-service'
import Link from 'next/link'
import AccountContext from '../../../../contexts/account-context'
import InlineLink from '../../../InlineLink/inline-link'
import ensureResultsLoaded from '../../../../utils/ensure-results-loaded'
import RichTextDocumentContext from '../../../../contexts/rich-text-document-context'
import conditionalTextBlockEntriesToReactComponents from '../../../../utils/conditional-text-block-entries-to-react-components'

const Content = ({
  textAlign = undefined,
  width = undefined,
  children = undefined,
}: PropsWithChildren<{
  textAlign?: string,
  width?: string,
}>) => (
  <UiContent textAlign={textAlign} width={width} content={children} />
)

const ResultsHistoryModule = ({
  modules,
}: {
  modules: ConditionalTextBlockType[],
}) => {
  const { results, errors } = useContext(AccountContext).resultsContext
  ensureResultsLoaded()

  const contextProviderValue = useMemo(
    () => ({}),
    [],
  )

  if (errors?.errors) {
    return (
      <>
        <h2>An error occurred</h2>
        <p>
          There was a problem displaying your results. This has been reported
          and will be looked in to shortly. Please come back and try again
          later.
        </p>
        <p>
          If you have seen this page for longer than 48 hours,{' '}
          <a href="https://sh24.org.uk/contact-us">contact us</a> and let us
          know.
        </p>
        <ButtonLink variation="primary" animation="shiftLeft" iconPosition="left">
          <InlineLink url="/results" text="Back to order history" iconName="arrow-left" iconPosition="left" />
        </ButtonLink>
      </>
    )
  }

  if (!results) {
    return <p>Loading results...</p>
  }

  return (
    <>
      <h3>New result cards go here!</h3>
      {results.orders.map(
        (result) => (
          <div key={result.sh24Uid}>
            <Link href={`/results/${(result.sh24Uid)}`} prefetch={false}>
              {result.sh24Uid}
            </Link>
          </div>
        ),
      )}
      <RichTextDocumentContext.Provider value={contextProviderValue}>
        {conditionalTextBlockEntriesToReactComponents(modules, contextProviderValue)}
      </RichTextDocumentContext.Provider>
    </>
  )
}

export default ({
  modules,
}: {
  modules: ConditionalTextBlockType[],
}) => (
  <Content textAlign="left" width="standard">
    <ResultsHistoryModule modules={modules} />
  </Content>
)
