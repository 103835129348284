import { Prescription, PrescriptionsData } from '@sh24/admin-api-js'
import { ByYearAndDate, Tag } from '@sh24/ui-components'
import styled from 'styled-components'
import PrescriptionCard from './prescription-card'
import useTranslations from '../../utils/use-translations'

const PrescriptionSummaryTag = styled(Tag)`
  ${({ theme }) => `
    margin-top: ${theme?.spacing?.sm};
    margin-right: ${theme?.spacing?.sm};
    margin-bottom: ${theme?.spacing?.sm};
  `}
`

const PrescriptionsPanelOrderDisplay = ({ allPrescriptions }: { allPrescriptions: PrescriptionsData}) => {
  const translations = useTranslations()
  const renderPrescriptionCard = (prescription: Prescription) => {
    const prescriptionFormText = () => {
      if (prescription.form === 'patch') {
        return prescription.totalUnits > 1 ? 'es' : ''
      }

      return prescription.totalUnits > 1 ? 's' : ''
    }

    const prescriptionSupplyLength = prescription.courseLengthInMonths ? `${prescription.courseLengthInMonths} ${translations['prescriptionCard.lengthOfSupply']} - ` : ''
    const prescriptionMethodString = `${prescription.totalUnits} ${prescription.form}${prescriptionFormText()}`

    const prescriptionDetails = [{
      title: prescription.drugName,
      description: prescription.ingredients,
      prescriptionQuantities: `${prescriptionSupplyLength} ${prescriptionMethodString}`,
    }]

    return (
      <PrescriptionCard
        items={prescriptionDetails}
        howToTake={translations['prescriptionCard.howToTake']}
        tagText={translations[`prescriptionCard.productType.${prescription.productType}`]}
        tagIcon="tablet-capsule"
        sh24Uid={prescription.sh24Uid}
        instructions={prescription.instructions}
        prescribedAt={prescription.prescribedAt}
      />
    )
  }

  const prescriptionsDisplay = allPrescriptions.prescriptions
    .sort(({ prescribedAt: a }, { prescribedAt: b }) => b.valueOf() - a.valueOf())
    .map((prescription) => {
      const tagText = translations[`prescriptionCard.productType.${prescription.productType}`]

      return (
        {
          date: prescription.prescribedAt,
          content: renderPrescriptionCard(prescription),
          previewContent: <PrescriptionSummaryTag text={tagText} icon="tablet-capsule" />,
        }
      )
    })

  return (
    <ByYearAndDate items={prescriptionsDisplay} />
  )
}

export default PrescriptionsPanelOrderDisplay
