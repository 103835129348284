import type { ConditionalTextBlock as ConditionalTextBlockType } from '@sh24/contentful-service'
import { ResultsModule, ResultsHistoryModule } from './dynamic-modules'
import PrescriptionsModule from './dynamic-modules/prescriptions-module'

const DynamicContentModule = ({
  type,
  modules,
}: {
  type: string,
  modules: ConditionalTextBlockType[]
}) => {
  switch (type) {
    case 'results':
      return <ResultsModule modules={modules} />
    case 'results_history':
      return <ResultsHistoryModule modules={modules} />
    case 'prescriptions':
      return <PrescriptionsModule />
    default:
      return <div>Unknown dynamic content</div>
  }
}

export default DynamicContentModule
