import {
  Action,
  State,
} from './types'

export const initialState = {
  token: null,
  invitationError: false,
  slotsByDate: [],
  bookedAt: null,
  loading: false,
  selectedSlot: null,
  slotTaken: false,
  dataFetched: false,
}

export enum ActionTypes {
  APPOINTMENT_CANCELLATION_REQUESTED = 'APPOINTMENT_CANCELLATION_REQUESTED',
  APPOINTMENT_CANCELLED = 'APPOINTMENT_CANCELLED',
  DATA_FETCHED = 'DATA_FETCHED',
  PAGE_READY = 'PAGE_READY',
  RECOVERABLE_ERROR_OCCURRED = 'RECOVERABLE_ERROR_OCCURRED',
  SLOT_BOOKED = 'SLOT_BOOKED',
  SLOT_BOOKING_REQUESTED = 'SLOT_BOOKING_REQUESTED',
  SLOT_TAKEN = 'SLOT_TAKEN',
  UNRECOVERABLE_ERROR_OCCURRED = 'UNRECOVERABLE_ERROR_OCCURRRED',
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.PAGE_READY: {
      return { ...state, token: action.token || null }
    }
    case ActionTypes.UNRECOVERABLE_ERROR_OCCURRED: {
      return { ...state, invitationError: true }
    }
    case ActionTypes.DATA_FETCHED: {
      return {
        ...state, bookedAt: action.bookedAt || null, slotsByDate: action.slotsByDate || [], dataFetched: true,
      }
    }
    case ActionTypes.SLOT_BOOKING_REQUESTED: {
      return { ...state, selectedSlot: action.selectedSlot || null, loading: true }
    }
    case ActionTypes.APPOINTMENT_CANCELLATION_REQUESTED: {
      return { ...state, loading: true }
    }
    case ActionTypes.SLOT_TAKEN: {
      return { ...initialState, slotTaken: true }
    }
    case ActionTypes.SLOT_BOOKED:
    case ActionTypes.APPOINTMENT_CANCELLED:
    case ActionTypes.RECOVERABLE_ERROR_OCCURRED: {
      return { ...initialState }
    }
    default: {
      return { ...state }
    }
  }
}

export default reducer
