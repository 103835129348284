import { useRouter } from 'next/router'
import Link from 'next/link'
import cardConfig from './card-config'
import useTranslations from '../../../utils/use-translations'
import useDiscountSettings from '../../../utils/use-discount-settings'
import { useSectionContext } from '../../../contexts/section-context'
import { useGridContext } from '../../../contexts/grid-context'
import Image from '../../Image/image'
import { formatPrice } from '../../../utils/helpers'
import documentToReactComponents from '../../../utils/document-to-react-components'
import { calculateMultiProductDiscount } from '../../../order-journeys/_v2/calculators'

const getPriceText = ({
  minPrice,
  maxPrice,
  hasMultiple,
  beforeText,
}) => {
  if (minPrice && maxPrice) {
    return `${minPrice}${hasMultiple ? ` - ${maxPrice}` : ''}`
  }

  if (minPrice) {
    return `${hasMultiple ? `${beforeText} ` : ''}${minPrice}`
  }

  return null
}

const getComparisonComponents = (comparisons, initial) => {
  if (!comparisons) return initial

  const comparisonEntries = Object.entries(comparisons)
  return comparisonEntries.reduce((prev, current) => {
    const [key, value] = current
    return {
      ...prev,
      [key]: documentToReactComponents(value),
    }
  }, initial)
}

const getCardColour = (pointer) => {
  const regex = /500|400|300|200|100/
  if (pointer?.match(regex)) {
    return pointer?.replace(regex, '400')
  }
  return '/white'
}

const getContent = (content) => {
  if (content?.nodeType === 'document') {
    return documentToReactComponents(content)
  }
  return content
}

const getSource = (source) => {
  if (!source) return null

  return {
    ...source,
    image: source?.image
      ? (
        <Image
          image={source.image}
          maxWidth={48}
          maxHeight={48}
          layout="intrinsic"
        />
      )
      : null,
  }
}

const CardModule = ({
  id,
  cardType,
  backgroundColour,
  image,
  title,
  tag,
  content,
  priceItem,
  callToActionText,
  link,
  comparisons,
  source,
  callToActionButtonVariation,
  secondaryCallToActionInternalLink,
  secondaryCallToActionExternalLink,
  secondaryCallToActionText,
  showSecondaryCallToActionWithIcon,
  textColour,
  callToActionExternalLink,
}) => {
  const sectionContext = useSectionContext()
  const gridContext = useGridContext()
  const translations = useTranslations()
  const discountSettings = useDiscountSettings()
  const currency = 'GBP'
  const { locale } = useRouter()
  const {
    imageSizes, Card, Button, SecondaryLink,
  } = cardConfig[cardType]
  let bgColour = backgroundColour
  const priceComparison = {}
  let priceText = ''

  if (gridContext?.inheritBackground) {
    bgColour = getCardColour(sectionContext?.backgroundColour)
  }

  if (priceItem) {
    const {
      minPrice,
      maxPrice,
      hasMultiplePrices,
      options,
      isBundle,
      inStock,
    } = priceItem

    const optionsWithPrices = options
      ? options.map((opt) => ({ ...opt, price: opt.price[currency] }))
      : null

    const discount = isBundle && optionsWithPrices
      ? calculateMultiProductDiscount({ basket: optionsWithPrices, discountSettings })
      : 0

    const minPriceForCurrency = minPrice?.[currency]

    const formattedMinPrice = minPriceForCurrency
      ? formatPrice({ price: (minPriceForCurrency + discount), locale, currency })
      : null

    const maxPriceForCurrency = maxPrice?.[currency]

    const formattedMaxPrice = maxPriceForCurrency
      ? formatPrice({ price: maxPriceForCurrency, locale, currency })
      : null

    priceText = inStock ? getPriceText({
      minPrice: formattedMinPrice,
      hasMultiple: hasMultiplePrices?.[currency],
      beforeText: translations['productPage.beforePriceText'],
    }) : translations.outOfStock

    priceComparison.price = getPriceText({
      minPrice: formattedMinPrice,
      maxPrice: formattedMaxPrice,
      hasMultiple: hasMultiplePrices?.[currency],
    })
  }

  const imageComponent = (image && imageSizes) ? (
    <Image
      image={image}
      layout={imageSizes.layout || 'intrinsic'}
      maxWidth={imageSizes.width}
      maxHeight={imageSizes.height}
    />
  ) : null

  const ctaButton = () => {
    if (Button
      && (link || callToActionExternalLink)
      && callToActionText) {
      return (
        <Button
          text={callToActionText}
          variation={callToActionButtonVariation || 'primary'}
          link={link || callToActionExternalLink}
        />
      )
    }

    return null
  }

  const secondaryCtaButton = () => {
    if (SecondaryLink
      && (secondaryCallToActionInternalLink || secondaryCallToActionExternalLink)
      && secondaryCallToActionText) {
      return (
        <SecondaryLink
          text={secondaryCallToActionText}
          link={secondaryCallToActionInternalLink || secondaryCallToActionExternalLink}
          withIcon={showSecondaryCallToActionWithIcon}
        />
      )
    }

    return null
  }

  const comparisonComponents = getComparisonComponents(comparisons, priceComparison)

  const linkWrap = (linkContent) => (link ? (
    <Link href={link} passHref prefetch={false}>
      <a>
        {linkContent}
      </a>
    </Link>
  ) : linkContent)

  return (
    <Card
      id={id}
      backgroundColour={bgColour}
      image={imageComponent}
      title={title}
      tag={tag}
      linkWrap={linkWrap}
      price={priceText}
      content={getContent(content)}
      comparisons={comparisonComponents}
      source={getSource(source)}
      cta={ctaButton()}
      secondaryCta={secondaryCtaButton()}
      textColour={textColour}
    />
  )
}

export default CardModule
