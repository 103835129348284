import { formatPrice } from '../../../utils/helpers'

const buildProduct = ({
  product,
  mandatory,
  preselected,
  selectedOption,
  currency,
  locale,
}) => {
  const price = product?.price?.[currency]

  return {
    ...product,
    price,
    formattedPrice: price ? formatPrice({ price, locale, currency }) : null,
    preselected,
    mandatory,
    options: product.options?.map((option, index) => {
      const optionPrice = option?.price?.[currency]

      return {
        ...option,
        price: optionPrice,
        formattedPrice: optionPrice ? formatPrice({ price: optionPrice, locale, currency }) : null,
        selected: selectedOption ? (option.identifier === selectedOption) : (index === 0),
      }
    }),
  }
}

export default buildProduct
